<style lang="less" scoped>
  #main {
    width: 80%;
    min-height: 400px;
    margin: 10px auto;
  }

  .table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 80%;
    margin: 10px auto 40px;
    text-align: center;
    thead {
      background: #f9f9f9;
      font-size: 12px;
      vertical-align: middle;
      color: #363b42;
      th {
        padding: 8px;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
      }
    }
    tbody {
      vertical-align: middle;
      tr {
        height: 30px;
        td {
          line-height: 22px;
          height: 22px;
          font-size: 13px;
          border-bottom: 1px solid #f2f2f2;
        }
        &:hover {
          background: #f9f9f9;
        }
      }
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>采购型号年度报表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div>
        <el-form :inline="true">
          <el-form-item v-if="isAdmin">
            <el-select
              style="width:100%;"
              v-model="customer"
              filterable
              remote
              clearable
              :remote-method="queryBrander"
              placeholder="选择品牌商"
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="year"
              placeholder="请选择年份"
              @change="getMonth"
            >
              <el-option
                v-for="(item, index) in yearList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="index" placeholder="请选择月份" clearable>
              <el-option
                v-for="(item, index) in month"
                :key="item"
                :label="item + '月'"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div id="main"></div>
      <table class="table">
        <thead>
          <tr>
            <th>时间</th>
            <th>小计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in 12" v-if="tableData[item]">
            <td>{{ item }}月</td>
            <td>{{ tableData[item] }}</td>
          </tr>
          <tr v-if="total">
            <td style="font-weight: bold;">Total</td>
            <td>{{ total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    getProductAuthoData,
    getShopData,
    getAlertCountData,
    getOnlineData,
  } from "../../../services/report";
  import { mapGetters } from "vuex";
  import { Notification } from "element-ui";
  import echarts from "echarts";
  import { API_ROOT, PAGE_SIZE } from "../../../config";
  import { queryBrander } from "../../../services/customer";
  export default {
    data() {
      return {
        isAdmin: false,
        customer: null,
        allCustomer: "all",
        type: "",
        year: "",
        yearList: [],
        tableData: {},
        brandList: [],
        month: 12,
        index: "",
        PAGE_SIZE,
        monthShow: 12,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 0,
        dvcTypeList: [],
        legendList: [],
        chartOption: {
          // title : {
          //   text: '同名数量统计',
          //   x:'center'
          // },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            data: [],
          },
          series: [
            {
              name: "型号",
              type: "pie",
              radius: "55%",
              center: ["40%", "50%"],
              data: [],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    mounted() {
      this.getYear();
      this.checkRole();
      this.queryBrander("");
      this.getData();
    },
    methods: {
      //角色判断
      checkRole() {
        const role = this.user.role;
        if (/admin/.test(role)) {
          this.isAdmin = true;
          //alert(this.isAdmin)
        } else {
          this.customer = this.user.customerId;
        }
      },
      // 初始化年份
      getYear() {
        const date = new Date();
        const year = date.getFullYear();
        this.yearList = [year, year - 1];
        this.year = year;
      },
      getMonth() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        if (this.year === year) {
          this.month = month;
        } else {
          this.month = 12;
        }
      },
      /**
       * 获取所有品牌商
       */
      async queryBrander(key) {
        const res = await queryBrander({ isProxy: true });
        if (res && res.errorCode === 0) {
          this.brandList = (res && res.data) || [];
        }
        this.loaded = true;
      },

      //产品型号授权数量报表
      async getData() {
        // if (this.customer === '') {
        //   this.customer = null;
        // }
        let params = {
          customer: this.customer,
          year: this.year,
          allCustomer: this.isAdmin ? this.allCustomer : null,
        };
        this.monthShow = this.month;
        let res = await getShopData(params);
        if (
          res &&
          res.errorCode === 0 &&
          res.data &&
          res.data.datas.length > 0
        ) {
          let temp;
          this.tableData = {};
          this.legendList = [];
          this.dvcTypeList = [];
          res.data.datas.forEach((item) => {
            if (item.deviceType) {
              let count = 0;
              if (this.index === "") {
                for (let key in item.map) {
                  count += item.map[key];
                }
              } else {
                count = item.map[this.index];
              }
              this.dvcTypeList.push({
                name: item.deviceTypeCode + item.productTypeCode,
                value: count,
              });
              this.legendList.push(item.deviceTypeCode + item.productTypeCode);
            } else {
              this.total = 0;
              this.tableData = item.map;
              for (let key in item.map) {
                this.total += item.map[key];
              }
            }
          });
          this.initChart();
          //this.tableData.push(temp);
        } else {
          //this.tableData = [];
          Notification.warning({
            title: "提示",
            message: "暂无数据更新",
          });
        }
      },
      initChart() {
        this.chartOption.legend.data = this.legendList;
        // console.log(1);
        // console.log(this.legendList)
        // console.log(2)
        // console.log(this.dvcTypeList)
        // this.chartOption.legend.data = [1,2,3];
        this.chartOption.series[0].data = this.dvcTypeList;
        // this.chartOption.series[0].data = [{name:'ni', value:10},{name:'ni', value:10},{name:'ni', value:10},];
        let myChart = echarts.init(document.getElementById("main"));
        myChart.setOption(this.chartOption);
      },
      /**
       * 翻页
       */
      handleCurrentChange(page) {
        // if (this.loaded) {
        this.pager.page = page;
        //window.router.replace({path: 'list', query: {page: page}})
        this.getData();
        // }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.getData();
      },
    },
    watch: {
      customer(newval) {
        if (newval === "") {
          this.customer = null;
          this.allCustomer = "all";
        } else {
          this.allCustomer = null;
        }
        if (!this.customer) {
          this.allCustomer = "all";
        }
      },
    },
  };
</script>
